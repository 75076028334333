<template>
  <v-dialog
    :value="visible"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Excluir
      </v-card-title>

      <v-card-text class="mt-5">
        Esta ação não pode ser desfeita. Deseja continuar?
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          color="info"
          text
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="confirm"
        >
          Excluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirmation',
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    close() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style></style>
