import api from '@/services/api';

const url = 'lgpd';

const getLgpd = () => api.get(url);

const createLgpd = (lgpd) => api.post(url, lgpd);

const updateLgpd = (lgpd) => api.put(url, lgpd);

const deleteLgpd = (id) => api.delete(`${url}/${id}`);

export default {
  getLgpd, createLgpd, updateLgpd, deleteLgpd,
};
